<template>
  <div>
    <div v-if="loadingFormData" class="text-center text-danger my-2">
      <b-spinner class="align-middle"></b-spinner>
      <strong class="ml-1">{{ $t('CATALOG_LOADING_LABEL', {catalog: $t('PAYMENT')})  | capitalize }}</strong>
    </div>
    <div v-else>
      <requisition-selector @input="getInvoices" :selection-mode="'single'" class="w-100 mb-1"
                            v-model="$v.form.requisition.$model"
                            :initial-values="[requisition]"
                            :state="state('requisition')" :only-active="true" :only-authorized="true"></requisition-selector>

      <div v-if="loadingInvoices" class="text-center text-danger my-2">
        <b-spinner class="align-middle"></b-spinner>
        <strong class="ml-1">{{ $t('CATALOG_LOADING_LABEL', {catalog: $t('INVOICES')})  | capitalize }}</strong>
      </div>

      <b-card v-if="!loadingInvoices && invoices.length > 0" class="mb-1" bg-variant="dark" text-variant="light"
              :title="$t('REQUISITION_INVOICES_LABEL') | capitalize">
        <b-card-text>
          <table class="w-100">
            <thead>
            <tr>
              <th>{{ $t('REQUISITION_INVOICE_NUMBER_LABEL') | capitalize }}</th>
              <th>{{ $t('REQUISITION_INVOICE_DATE_LABEL') | capitalize }}</th>
              <th>{{ $t('REQUISITION_INVOICE_TOTAL_LABEL') | capitalize }}</th>
              <th>{{ $t('REQUISITION_INVOICE_BALANCE_LABEL') | capitalize }}</th>
              <th>{{ $t('PAYMENT_AMOUNT_LABEL') | capitalize }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(v, idx) in $v.form.payments.$each.$iter" :key="idx">
              <td>{{ v.number.$model }}</td>
              <td>{{ v.date.$model | date }}</td>
              <td>$ {{ v.total.$model | currency }}</td>
              <td>$ {{ v.balance.$model | currency }}</td>
              <td>
                <b-input :id="`amount-${idx}`" :state="state2(v, 'amount')" v-model="v.amount.$model"></b-input>

                <b-form-invalid-feedback :id="`amount-${idx}-feedback`">
                  <div v-for="error in errors2(v, 'amount')" :key="error.error">
                    {{
                      $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('PAYMENT_AMOUNT_LABEL')},})  | capitalize
                    }}
                  </div>
                </b-form-invalid-feedback>
              </td>
            </tr>
            </tbody>
          </table>
        </b-card-text>
      </b-card>


      <b-card bg-variant="dark" text-variant="light" :title="$t('FORMS_GENERAL_DATA_TITLE') | capitalize">
        <b-card-text>
          <pre v-if="debug">{{ form }}</pre>

          <b-form @submit.prevent="save">
            <!-- payment-date text input -->
            <b-form-group
                id="payment-date-form-group"
                :label="$t('PAYMENT_DATE_LABEL') | capitalize"
                label-for="payment-date-input"
                :state="state('paymentDate')">
              <b-datepicker class="w-100 ml-1" id="payment-date-input" type="text"
                            v-model="$v.form.paymentDate.$model"
                            :state="state('paymentDate')"
                            trim></b-datepicker>

              <b-form-invalid-feedback id="payment-date-input-feedback">
                <div v-for="error in errors('paymentDate')" :key="error.error">
                  {{
                    $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('PAYMENT_CONCEPT_LABEL')},})  | capitalize
                  }}
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
            <!-- payment-date text input -->
            
            <!-- concept text input -->
            <b-form-group
                id="concept-form-group"
                :label="$t('PAYMENT_CONCEPT_LABEL') | capitalize"
                label-for="concept-input"
                :state="state('concept')">
              <b-form-input class="w-100 ml-1" id="concept-input" type="text"
                            v-model="$v.form.concept.$model"
                            :state="state('concept')"
                            trim></b-form-input>

              <b-form-invalid-feedback id="concept-input-feedback">
                <div v-for="error in errors('concept')" :key="error.error">
                  {{
                    $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('PAYMENT_CONCEPT_LABEL')},})  | capitalize
                  }}
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
            <!-- concept text input -->

            <!-- observations text input -->
            <b-form-group
                id="observations-form-group"
                :label="$t('PAYMENT_OBSERVATIONS_LABEL') | capitalize"
                label-for="observations-input"
                :state="state('observations')">
              <b-textarea class="w-100 ml-1" id="observations-input" type="text"
                          v-model="$v.form.observations.$model"
                          :state="state('observations')"
                          trim></b-textarea>

              <b-form-invalid-feedback id="observations-input-feedback">
                <div v-for="error in errors('observations')" :key="error.error">
                  {{
                    $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('PAYMENT_OBSERVATIONS_LABEL')},})  | capitalize
                  }}
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
            <!-- observations text input -->

            <b-button class="mt-2" type="submit" :disabled="saving" variant="primary">
              <b-icon v-if="saving" icon="circle-fill" animation="throb" class="mr-2"></b-icon>
              <span v-if="saving">{{ $t('FORMS_SAVING_LABEL')  | capitalize }}</span>
              <span v-else>{{ $t('FORMS_SAVE_LABEL')  | capitalize }}</span>
            </b-button>
          </b-form>
        </b-card-text>
      </b-card>
    </div>
  </div>
</template>

<script>
import {maxLength, required, minLength, minValue} from "vuelidate/lib/validators";
import {DateFilter, Form, NumberFilter, ProcessWithLoadingAndMessage} from "@/mixins";
import Payment from "@/payment";
import Constants from "@/constants";
import RequisitionSelector from "@/requisition/RequisitionSelector";
import Requisition from "@/requisition";
import {v4} from "uuid";

export default {
  name: "PaymentForm",
  mixins: [Form, ProcessWithLoadingAndMessage, DateFilter, NumberFilter],
  components: {RequisitionSelector},
  methods: {
    async getInvoices(requisition) {
      try {
        this.loadingInvoices = true;
        console.log('Requisition is ' + requisition);
        const split = requisition.split('/');
        const id = split[split.length - 1];
        this.$v.form.payments.$model = [];
        this.invoices = await Requisition.invoices(id);
        console.log(this.invoices);
        this.invoices.forEach(invoice => {
          this.$v.form.payments.$model.push({invoice: invoice._links.self.href, number: invoice.number, date: invoice.date, total: invoice.total, balance: invoice.balance, amount: 0});
        });
      } catch (e) {
        this.sendError('ERROR_LOADING_INVOICES', {}, e);
      } finally {
        this.loadingInvoices = false;
      }
    },
    beforeSave(form) {
      form.$touch();
      if (form.$invalid) {
        if (Constants.DEBUG) {
          console.log(form);
        }
        throw new Error('Form is invalid');
      }
      form.payments.$model = form.payments.$model.filter(payment => payment.amount > 0);
    },
    async doSave(id, data) {
      return id ? await Payment.update(id, data) : await Payment.create(data);
    },
    getData() {
      this.loadingFormData = true;
      Payment.findById(this.id)
          .then(resp => {
            this.form.requisition = resp.data._embedded.requisition._links.self.href.replace('{?projection}', '');
            this.form.concept = resp.data.concept;
            this.form.observations = resp.data.observations;
            this.invoices = resp.data.payments.map(x => {
              return {
              invoice: x._embedded.invoice._links.self.href.replace('{?projection}', ''),
              number: x._embedded.invoice.number,
              date: x._embedded.invoice.date,
              total: x._embedded.invoice.total,
              balance: x._embedded.invoice.balance + x.amount,
              amount: x.amount,
            }});
            this.form.payments = this.invoices;
            return Payment.requisition(this.id);
          })
          .then((resp) => {
            this.requisition = resp.data;
          })
          .catch(e => {
            if (Constants.DEBUG) {
              console.error(e);
            }
            this.sendError('%loadingFormDataErrorMessage%', e);
          })
          .finally(() => {
            this.loadingFormData = false;
          });
    }
  },
  data() {
    return {
      loadingInvoices: false,
      loadingFormData: false,
      originals: {},
      requisition: {},
      invoices: [],
      form: {
        uploadsDirectory: v4(),
        paymentDate: new Date(),
        requisition: null,
        concept: null,
        observations: null,
        payments: []
      },
      editPage: 'PAYMENT_EDIT_TITLE'
    }
  },
  validations: {
    form: {
      requisition: {required},
      concept: {},
      paymentDate: {required},
      observations: {maxLength: maxLength(500)},
      payments: {
        minLength: minLength(1), $each: {
          number: {},
          date: {},
          total: {},
          balance: {},
          invoice: {required},
          amount: {
            required, minValue: minValue(0),
            maxValue: (value, vm) => value <= vm.balance
          }
        }
      }
    }
  },
  computed: {
    total() {
      return this.invoices ? this.invoices.reduce((total, num) => total + num) : 0;
    }
  }
}
</script>

<style scoped>

</style>
