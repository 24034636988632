<template>
  <div>
    <div v-if="isBusy || loading" class="text-center text-danger my-2">
      <b-spinner class="align-middle"></b-spinner>
      <strong class="ml-1">{{ $t('CATALOG_LOADING_LABEL', {catalog: $t('REQUISITIONS')}) | capitalize }}</strong>
    </div>
    <b-card bg-variant="dark" text-variant="light" v-else :title="$t('REQUISITION') | capitalize">
      <b-card-text>
        <b-button class="mb-2" variant="info" @click="showModal">
          {{ $t('FORMS_MULTI_SELECT_LABEL', {catalog: $t('REQUISITION')}) | capitalize }}
        </b-button>
        <b-list-group>
          <b-list-group-item variant="dark" :key="item.id" v-for="item of requisitions">
            <div>{{ $t('REQUISITION') | capitalize }}: {{ $t(item.number) }}</div>
            <div>{{ $t('REQUISITION_DONOR_LABEL') | capitalize }}: {{ $t(item.donor) }}</div>
            <div>{{ $t('REQUISITION_SUPPLIER_LABEL') | capitalize }}: {{ $t(item.supplier) }}</div>
            <div>{{ $t('REQUISITION_TOTAL_LABEL') | capitalize }}: {{ item.total | currency }}</div>
            <div>{{ $t('REQUISITION_PAYMENT_DATE_LABEL') | capitalize }}: {{ item.paymentDate | date }}</div>
          </b-list-group-item>
        </b-list-group>
        <b-modal id="requisition-modal" size="xl" @ok="handleInput">
          <requisition-list :select-mode="selectionMode" :read-only="true" :only-active="true" :only-authorized="onlyAuthorized"
                            :currently-selected="currentHrefs" @change="storeSelected"></requisition-list>
        </b-modal>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import {DateFilter, LoadData, NumberFilter, SendsMessages, VModel} from "@/mixins";
import RequisitionList from "@/requisition/RequisitionList";

export default {
  name: "RequisitionSelector",
  components: {RequisitionList},
  props: ['loading', 'value', 'initialValues', 'selectionMode', 'onlyActive', 'onlyAuthorized'],
  mixins: [LoadData, VModel, SendsMessages, NumberFilter, DateFilter],
  mounted() {
    this.currentHrefs = this.value;
    this.current = this.initialValues;
    this.requisitions = this.initialValues;
  },
  watch: {
    value(val) {
      this.currentHrefs = val;
    },
    initialValues(val) {
      this.current = val;
    }
  },
  methods: {
    storeSelected(selected) {
      this.selected = selected;
      this.selectedHrefs = selected.map(x => x._links.self.href);
      this.requisitions = selected;
    },
    async showModal() {
      this.$bvModal.show('requisition-modal');
    },
    handleInput() {
      this.$emit('input', this.selectionMode === 'single' ? this.selectedHrefs[0] : this.selectedHrefs);
      this.current = this.selected;
      this.currentHrefs = this.current.map(x => x._links.self.href)

      console.log('current')
      console.log(this.current)
      // this.selected = [];
      // this.selectedHrefs = [];
    }
  },
  data() {
    return {
      search: '',
      permissions: [],
      requisitions: [],
      content: this.value,
      selected: [],
      selectedHrefs: [],
      current: [],
      currentHrefs: []
    }
  },
}
</script>

<style scoped>

</style>
